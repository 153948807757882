.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
  padding-bottom: 20px;
}

.ona-dark {
  color: rgb(27, 27, 27);
}

.ona-p {
  margin: 0;
  padding: 0;
}

.ona-heading {
  font-weight: 400;
}

.sans-pro {
  font-family: "Source Sans Pro", arial, sans-serif;
  font-weight: 700;
}

.ona-button {
  border: none;
  border-radius: 1em;
  font-size: larger;
  color: white;
  padding: 10;
  background-color: rgb(88, 72, 241);
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.App-header {
  height: min-content;
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
